<template>
  <div>
    <template>
      <ClustersConflictsList
        class="mb-4"
        :clusters="data.clusters || []"
        :pagination="pagination"
        :total="data.total || 0"
        @apply="applyConflicts"
        @change-count="changeCount"
        @change-page="changePage"
        @keep="keepConflicts"
        @searching="$emit('searching', $event)"
        @set-date="$emit('set-date', $event)"
        @clear-filters="$emit('clear-filters')"
      />
    </template>
  </div>
</template>

<script>
// Components
import ClustersConflictsList from '@/pages/tagging/components/ClustersConflictsList'

export default {
  components: {
    ClustersConflictsList,
  },
  props: {
    data: {
      type: [Object, Array],
      default: () => ({})
    },
    pagination: {
      type: Object,
      default: () => ({})
    },
  },
  methods: {
    applyConflicts(type, id, index) {
      this.$emit(`${type}-apply`, type, id, index)
    },
    changePage(val) {
      this.$emit('change-page', val)
    },
    changeCount(val) {
      this.$emit('change-count', val)
    },
    keepConflicts(type, id) {
      this.$emit(`${type}-keep`, type, id)
    }
  }
}
</script>

<style>
.conflicts-empty {
  /*position: absolute;*/
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--dark-grey);
  padding: 10px;
}
</style>
