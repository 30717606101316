<template>
  <div class="tagging__sub-block">
    <div class="mb-4">
      <img
        alt="address-tags"
        src="../../../../public/assets/img/cluster-tags.png"
        style="width: 100%"
      >
    </div>
    <div class="tagging__sub-title mb-4">
      Cluster Labels
    </div>
    <div class="tagging__main-text ">
      <div class="mb-4">
        <div>
          Load labels that are associated with the cluster owners. Enter one of the cluster addresses to fill the address field in the CSV
        </div>
        <div>Use the template below to fill out the CSV file.</div>
      </div>
      <gl-menu-item
        class="fs-14 mb-4"
        icon="import"
        :icon-height="24"
        :icon-width="24"
        label="Download CSV Template"
        @click="csvExport(clusterTaggingExample, 'cluster-example')"
      />
      <button
        class="gl-button gl-button--dark gl-button--padder gl-button-bulk"
        @click="openFileInput"
      >
        Upload cluster labels
      </button>
      <input
        ref="clusterUploader"
        accept=".csv"
        :style="{display: 'none'}"
        type="file"
        @change="onFileChange"
      >
      <FailUploadedModal
        v-model="failUpload"
        type="address"
        @close="failUpload = false"
      />
      <ClusterTagsUploadedModal
        v-model="clustersUploaded"
        :data="resultData"
        :file-name="file.name"
        @close="clustersUploaded = false"
      />
    </div>
  </div>
</template>

<script>
// Utils
import apiService from '@/utils/api-client'
import { csvExport } from '@/utils/export-data'
// Mixins
import sidebarTrackingMixin from "@/assets/mixins/sidebarTrackingMixin";
// Components
import GlMenuItem from '@/components/gl-menu-item'
import FailUploadedModal from '@/pages/tagging/modals/FailUploadedModal'
import ClusterTagsUploadedModal from '@/pages/tagging/modals/ClusterTagsUploadedModal'
// Static
import { clusterTaggingExample } from '@/assets/static/cluster-tagging-example'

export default {
  components: {
    GlMenuItem,
    FailUploadedModal,
    ClusterTagsUploadedModal,
  },
  mixins: [sidebarTrackingMixin],
  data() {
    return {
      file: '',
      resultData: {},
      failUpload: false,
      clustersUploaded: false,
    }
  },
  computed: {
    clusterTaggingExample() {
      return clusterTaggingExample;
    },
  },
  methods: {
    csvExport,
    openFileInput() {
      this.$refs.clusterUploader.click()
    },
    onFileChange(e) {
      this.file = e.target.files[0]
      e.target.value = ''

      let formData = new FormData();

      formData.append('file', this.file);

      apiService.post( '/labeling/uploadClusters',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            this.$emit('start-uploading', percentCompleted, this.file)
          }
        }
      ).then(({ data, success }) => {
        if (success) {
          this.checkUntackedLabels()
          this.$emit('complete-uploading');
          this.resultData = {
            ...data,
            log: data.log.map(item => ({
              address: item.address,
              owner: item.owner,
              type: item.type,
              tags: `"${item.tags}"`,
              description: item.description,
              status: item.status,
              statusDetails: item.statusDetails,
            }))
          }
          this.clustersUploaded = true
        } else {
          this.failUpload = true
          this.$emit('error-uploading');
        }
      })
      .catch(() => {
        this.failUpload = true
        this.$emit('error-uploading');
      });
    },
  },
}
</script>
