<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    info-only
    title="Hold On"
    width="400"
    v-on="$listeners"
  >
    <template #content>
      <div class="mb-4">
        <strong class="word-break">{{ fileName }}</strong> is currently uploading to the system.
      </div>
      <GlProgress
        v-if="progress < 100"
        class="mb-4"
        :progress="progress"
      />
      <div v-if="$attrs.value && progress === 100">
        <div class="flex align-center justify-center">
          <gl-loader />
        </div>
        File uploaded <br> Data processing is in progress
      </div>
    </template>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlProgress from '@/components/gl-progress'
import GlLoader from '@/components/gl-loader'

export default {
  components: {
    GlModal,
    GlProgress,
    GlLoader,
  },
  inheritAttrs: false,
  props: {
    progress :{
      type: Number,
      default: 0
    },
    fileName :{
      type: String,
      default: 'index.csv',
    },
  }
}
</script>