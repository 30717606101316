<template>
  <ValidationProvider
    ref="datepicker"
    v-slot="{ errors, required, ariaInput, ariaMsg, reset }"
    :name="name || label"
    :rules="rules"
    tag="div"
    :vid="vid"
  >
    <div
      v-if="label"
      class="tagging__key mb-2"
      :class="{ 'gl-date-picker__label--invalid': errors[0] }"
    >
      {{ label }}
    </div>
    <date-picker
      :id="name"
      ref="glDatePicker"
      v-model="innerValue"
      class="gl-date-picker"
      :disabled="disabled"
      :disabled-date="disabledRange"
      format="YYYY-MM-DD HH:mm:ss"
      placeholder="Select datetime range"
      range
      :show-time-panel="showTimeRangePanel"
      type="datetime"
      @clear="clear"
      @close="handleRangeClose"
      @pick="pick"
    >
      <template v-slot:footer>
        <div class="flex space-between">
          <div>
            <button
              class="mx-btn mx-btn-text link"
              @click="toggleTimeRangePanel"
            >
              {{ showTimeRangePanel ? 'Select date' : 'Select time' }}
            </button>
          </div>
          <div>
            <button
              class="mx-btn mx-btn-text cancel-date-range mr-2"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              class="mx-btn mx-btn-text apply-date-range"
              @click="apply"
            >
              Apply
            </button>
          </div>
        </div>
      </template>
    </date-picker>
    <div>
      <span
        v-if="errors[0]"
        v-bind="ariaMsg"
        class="gl-date-picker__error"
      >{{
        errors[0]
      }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
export default {
  name: 'GlDatePicker',
  components: {
    ValidationProvider,
  },
  inheritAttrs: false,
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [Array, String, Number, Boolean],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'date',
    },
    disabledRange: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showTimeRangePanel: false,
    }
  },
  computed: {
    innerValue: {
      get: function () {
        return this.value
      },
      set: function (e) {
        this.$emit('change', e)
        this.$emit('input', e)
      },
    },
  },
  methods: {
    pick(date) {
      this.$emit('pick', date)
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel
    },
    handleRangeClose() {
      this.showTimeRangePanel = false
    },
    cancel() {
      this.$refs.glDatePicker.closePopup()
      this.$emit('cancel')
    },
    clear() {
      this.$refs.glDatePicker.closePopup()
      this.$emit('clear')
    },
    apply() {
      this.$refs.glDatePicker.closePopup()
      this.$emit('apply', this.innerValue)
    },
    closePopup() {
      this.$refs.glDatePicker.closePopup()
    },
  },
}
</script>

<style>
.gl-date-picker input {
  background: var(--cotton-grey-f-5) !important;
  border: none;
  box-shadow: none;
}

.gl-date-picker__label--invalid {
  color: var(--lipstick);
}
.gl-date-picker__error {
  font-size: 12px;
  color: var(--lipstick);
}
</style>
