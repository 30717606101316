<template>
  <div class="tagging__sub-block">
    <div class="tagging__block-title mb-4">
      General Info
    </div>
    <div class="flex m-column m-gap-3">
      <div class="item flex-1 mr-4">
        <div class="tagging__key mb-2">
          Address
        </div>
        <VueSkeletonLoader
          v-if="loading"
          animation="wave"
          :color="'#bec3d8'"
          :height="20"
          :radius="'2'"
          type="rect"
        />
        <div
          v-else
          class="tagging__value ellipsis"
        >
          {{ data.address || '—' }}
        </div>
      </div>
      <div class="flex flex-1">
        <div class="flex-1">
          <div class="tagging__key mb-2">
            Cluster id
          </div>
          <VueSkeletonLoader
            v-if="loading"
            animation="wave"
            :color="'#bec3d8'"
            :height="20"
            :radius="'2'"
            type="rect"
          />
          <div
            v-else
            class="tagging__value"
          >
            {{ data.cluster || '—' }}
          </div>
        </div>
        <div class="flex-1">
          <div class="tagging__key mb-2">
            Cluster size
          </div>
          <VueSkeletonLoader
            v-if="loading"
            animation="wave"
            :color="'#bec3d8'"
            :height="20"
            :radius="'2'"
            type="rect"
          />
          <div
            v-else
            class="tagging__value"
          >
            {{ data.clusterData && data.clusterData.clusterSize ? toComaSeparate(data.clusterData.clusterSize) : '—' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import _ from 'lodash';
// Components
import VueSkeletonLoader from 'skeleton-loader-vue';
// Utils
import { toComaSeparate } from "@/utils/formatNumber"

export default {
  components: {
    VueSkeletonLoader,
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localData: {},
    }
  },
  watch: {
    data: {
      handler(val) {
        this.localData = _.clone(val);
      },
      immediate: true,
    },
  },
  methods: {
    toComaSeparate
  },
}
</script>