export default [
    {
        address: '3265tcUcp8dBhBBwp4rKN3iyUptuHkzMq7',
        owner: 'Example owner',
        type: 'Exchange',
        tags: "[tag1^ tag description1], [tag2^ tag description2]",
        description: 'Some description1',
    },
    {
        address: '1Pi3bxWgjQm5XbKZcMvB7YsgBTaMst69zg',
        owner: 'Example owner',
        type: 'Exchange',
        tags: "[tag1^ tag description1], [tag2^ tag description2]",
        description: 'Some description',
    },
]
