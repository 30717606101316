<template>
  <div>
    <div class="tagging__block-title mb-4">
      Address Labeling History
    </div>
    <o-table
      class="history-table table__overflow-auto"
      :data="data"
      hoverable
      :loading="loading"
      :mobile-cards="false"
    >
      <o-table-column
        v-slot="props"
        field="taggetBy"
        label="labeled by"
        width="200px"
      >
        <div
          style="max-width: 110px;
    text-overflow: ellipsis;
    overflow: hidden;"
        >
          {{ props.row._editedBy || '-' }}
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="taggetAt"
        label="labeled at"
        width="220"
      >
        {{ props.row._editedAt ? `${moment(props.row._editedAt).utc().format('DD.MM.yyyy, HH:mm')} UTC` : '-' }}
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="tags"
        label="tags"
        width="300"
      >
        <div v-if="props.row.oldTagsData && props.row.oldTagsData.length">
          <GlTag
            v-for="(tag, index) in props.row.oldTagsData"
            :key="index"
            class="mr-1 mb-1"
            :score="tag.score"
            :tag="tag.name"
            :value="tag.score"
          />
        </div>
        <div v-else>
          <GlTag
            v-for="(tag, index) in props.row.tags"
            :key="index"
            class="mr-1 mb-1"
            :score="tag.score"
            :tag="tag.name"
            :value="tag.score"
          />
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="addressTag"
        label="Address Owner"
        width="250"
      >
        <div
          v-if="props.row.owner"
          v-tooltip.top="props.row.owner"
          class="short-col ellipsis"
        >
          {{ props.row.owner || '-' }}
        </div>
        <div v-else>
          -
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="type"
        label="Type"
        width="220"
      >
        <div>
          <span
            v-if="props.row.oldTypeData"
            v-tooltip.top="props.row.oldTypeData.name"
          >
            <GlTag
              class="mr-1 mb-1"
              :score="props.row.oldTypeData && props.row.oldTypeData.score"
              :tag="props.row.oldTypeData && props.row.oldTypeData.name"
              :value="props.row.oldTypeData && props.row.oldTypeData.name"
            />
          </span>
          <span
            v-else
            v-tooltip.top="props.row.type || ''"
          >
            {{ props.row.type || '-' }}
          </span>
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="description"
        label="description"
      >
        <div class="address-history__description ellipsis">
          {{ props.row.description || '-' }}
        </div>
      </o-table-column>
      <template #empty>
        <div class="empty-users-data flex column align-center text-center">
          <gl-icon
            class="mb-3"
            :height="24"
            name="find-dis"
            :width="24"
          />
          Type address in the search bar above to <br> preview address labels history
        </div>
      </template>
    </o-table>
    <o-pagination
      v-if="pagination.totalPages > 1"
      :current.sync="pagination.page"
      order="centered"
      :per-page="pagination.perPage"
      size="small"
      :total="pagination.total"
      @change="$emit('change-page', $event)"
    >
      <o-pagination-button
        slot="previous"
        slot-scope="props"
        :page="props.page"
      >
        <gl-icon
          name="prev"
        />
      </o-pagination-button>

      <o-pagination-button
        slot="next"
        slot-scope="props"
        :page="props.page"
      >
        <gl-icon
          name="next"
        />
      </o-pagination-button>
    </o-pagination>
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon';
import GlTag from '@/components/gl-tag';
// Utils
import { formatDate } from '@/utils/format-date'

import moment from "moment";

export default {
  components: {
    GlIcon,
    GlTag,
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    formatDate,
    moment,
  },
}
</script>

<style>
.address-history__description {
  max-width: 300px;
}

.short-col {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 130px;
}
</style>
