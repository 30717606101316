<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    submit-title="Add"
    title="Add New Tag"
    width="400"
    v-on="$listeners"
    @close="$emit('close', tag)"
    @submit="$emit('add', tag, score, metadata)"
  >
    <gl-input
      v-model="tag"
      class="gl-modal__row"
      :height="40"
      label="tag name *"
      name="tag"
      rules="required|max:25|min:2"
    />
    <gl-input
      v-model="score"
      v-mask="'###'"
      class="gl-modal__row"
      :height="40"
      label="tag score *"
      name="score"
      rules="required|max_value:100"
      type="number"
      @input="handleScoring"
    />
    <div class="mb-3 flex align-center">
      <gl-icon
        class="mr-2 gl-icon--disabled"
        :height="24"
        name="def-report"
        :width="24"
      />
      <div class="grey-text fs-12">
        Tag Score can be set from 0 to 100. The bigger score is the more severe risk.
      </div>
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlIcon from '@/components/gl-icon'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlIcon,
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      score: 0,
      metadata: '',
    }
  },
  methods: {
    handleScoring(score) {
      if (score && Number(score) > 100) {
        this.score = 100
      }
    },
  },
}
</script>
