<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    submit-title="Delete"
    title="Delete the Tag"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('submit', tag.name)"
  >
    <div class="mb-4">
      You are going to delete tag <strong>{{ tag.name }}</strong>. This action cannot be undone.
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    tag :{
      type: Object,
      default: () => ({})
    },
  },
}
</script>