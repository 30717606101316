<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    full-submit
    ok-only
    submit-title="Got it"
    title="Upload Failed"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('close')"
  >
    <div class="mb-4">
      Please check the file format or download the CSV template.
    </div>
    <gl-menu-item
      class="fs-14 mb-4"
      icon="import"
      :icon-height="24"
      :icon-width="24"
      label="Download CSV Template"
      @click="csvExport(type === 'address' ? addressTaggingExample : clusterTaggingExample)"
    />
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlMenuItem from '@/components/gl-menu-item'

import { csvExport } from '@/utils/export-data'
import { addressTaggingExample } from '@/assets/static/address-tagging-example'
import { clusterTaggingExample } from '@/assets/static/cluster-tagging-example'

export default {
  components: {
    GlModal,
    GlMenuItem,
  },
  inheritAttrs: false,
  props: {
    type :{
      type: String,
      default: 'cluster'
    },
  },
  computed: {
    addressTaggingExample() {
      return addressTaggingExample;
    },
    clusterTaggingExample() {
      return clusterTaggingExample;
    },
  },
  methods: {
    csvExport
  },
}
</script>