<template>
  <div>
    <AddressInfo
      :can-edit="Boolean(addressData && addressData.address)"
      class="mb-4"
      :data="addressData"
      :loading="loading"
      :search="search"
      @remove-tag="removeTag"
      @save-address-data="updateAddressData"
    />
    <AddressHistory
      class="mb-4"
      :data="history"
      :pagination="addressPagination"
      @change-page="changeAddressPage"
    />
  </div>
</template>

<script>
// Components
import AddressInfo from "@/pages/tagging/components/AddressInfo";
import AddressHistory from "@/pages/tagging/components/AddressHistory";
// Vuex
import { mapActions } from "vuex";
// Utils
import { isValidEthAddress } from "@/utils/cytoskape-ui-rules";

export default {
  components: {
    AddressInfo,
    AddressHistory,
  },
  props: {
    search: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    addressData: {
      type: Object,
      default: () => ({})
    },
    addressPagination: {
      type: Object,
      default: () => ({})
    },
    history: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      addressValid: false,
      uploading: false,
      uploadingFile: '',
      generalInfo: {},
      addressHistory: [],
      addressInfo: {},
    }
  },
  mounted() {
    this.formattedAddressHistory()
  },
  methods: {
    ...mapActions('tagging', ['updateWallet']),
    ...mapActions('analytics', ['getAddressHistory']),
    isValidEthAddress,
    changeAddressPage(page) {
      this.addressPagination.page = page;
      this.$emit('change-address-page', page)
      this.formattedAddressHistory();
    },
    updateAddressData(data) {
      this.addressInfo = data;
      this.$emit('update-wallet', this.search)
      this.formattedAddressHistory();
    },
    removeTag(tag) {
      const removeIndex = this.addressInfo.tags.findIndex(({ name }) => name === tag)
      this.addressInfo.tags.splice(removeIndex, 1)
      this.$emit('update-wallet', this.search)
    },
    formattedAddressHistory() {
      if (this.search && this.isValidEthAddress(this.search))
      this.getAddressHistory({ address: this.search, page: this.addressPagination.page }).then(({ data }) => {
        this.addressHistory = data.logs ? data.logs : []
        this.addressPagination = {
          page: Number(data.page),
          total: data.totalItems,
          perPage: data.itemsPerPage,
          totalPages: data.totalPages,
        }
      });
    },
  },
}
</script>
