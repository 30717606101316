<template>
  <div class="tagging__sub-block">
    <div class="mb-4">
      <img
        alt="address-tags"
        src="../../../../public/assets/img/address-tags.png"
        style="width: 100%"
      >
    </div>
    <div class="tagging__sub-title mb-4">
      Address Labels
    </div>
    <div class="tagging__main-text mb-4">
      <div>Load labels that are associated with individual addresses regardless of the cluster owner.</div>
      <div>Use the template below to fill out the CSV file.</div>
    </div>
    <gl-menu-item
      class="fs-14 mb-4"
      icon="import"
      :icon-height="24"
      :icon-width="24"
      label="Download CSV Template"
      @click="csvExport($can('use', 'btc')
                          ? addressTaggingExample
                          : ethAddressTaggingExample,
                        'address-example')"
    />
    <button
      class="gl-button gl-button--dark gl-button--padder gl-button-bulk"
      @click="openFileInput"
    >
      Upload address labels
    </button>
    <input
      ref="addressUploader"
      accept=".csv"
      :style="{display: 'none'}"
      type="file"
      @input="onFileChange"
    >
    <FailUploadedModal
      v-model="failUpload"
      type="address"
      @close="failUpload = false"
    />
    <AddressTagsUploadedModal
      v-model="addressUploaded"
      :data="resultData"
      :file-name="file.name"
      @close="addressUploaded = false"
    />
  </div>
</template>

<script>
// Vuex
import { mapState } from "vuex";
// Mixins
import sidebarTrackingMixin from "@/assets/mixins/sidebarTrackingMixin";
// Utils
import apiService from '@/utils/api-client'
// Components
import GlMenuItem from '@/components/gl-menu-item'
import FailUploadedModal from '@/pages/tagging/modals/FailUploadedModal'
import AddressTagsUploadedModal from '@/pages/tagging/modals/AddressTagsUploadedModal'

import { csvExport } from '@/utils/export-data'
import addressTaggingExample from '@/assets/static/address-tagging-example'
import ethAddressTaggingExample from "@/assets/static/eth-address-tagging-example";

export default {
  components: {
    GlMenuItem,
    FailUploadedModal,
    AddressTagsUploadedModal,
  },
  mixins: [sidebarTrackingMixin],
  data() {
    return {
      file: '',
      resultData: {},
      failUpload: false,
      addressUploaded: false,
      ethAddressTaggingExample,
      addressTaggingExample,
    }
  },
  computed: {
    ...mapState('analytics', ['coinType']),
  },
  methods: {
    csvExport,
    openFileInput() {
      this.$refs.addressUploader.click()
    },
    onFileChange(e) {
      this.file = e.target.files[0]
      e.target.value = ''

      let formData = new FormData();

      formData.append('file', this.file);

      apiService.post( `/labeling/uploadAddresses`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            this.$emit('start-uploading', percentCompleted, this.file)
          }
        }
      ).then(({ data, success }) => {
        if (success) {
          this.checkUntackedLabels()
          this.resultData = {
            ...data,
            log: data.log.map(item => ({
              address: item.address,
              owner: item.owner,
              type: item.type,
              tags: `"${item.tags}"`,
              description: item.description,
              status: item.status,
              statusDetails: item.statusDetails,
            }))
          }
          this.$emit('complete-uploading');
          this.addressUploaded = true
        } else {
          this.failUpload = true
          this.$emit('error-uploading');
        }
      })
      .catch(() => {
        this.failUpload = true
        this.$emit('error-uploading');
      });
    },
  },
}
</script>
