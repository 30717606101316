<template>
  <div>
    <div class="conflicts-block">
      <div>
        <div class="flex flex-wrap gap-3">
          <div class="pt-1 m-fullwidth">
            <div class="tagging__key mb-2">
              Filter by cluster
            </div>
            <gl-search-box
              v-model="search"
              button-text="Search"
              class="mr-2 m-fullwidth m-mr-0"
              dark-clear
              :disabled="!search"
              grey
              :min-width="isMobile ? 'auto' : '450px'"
              placeholder="Enter the cluster id"
              @clear="clearSearching"
              @search="searching"
            />
          </div>
          <div class="flex m-fullwidth">
            <div class="mr-2 pt-1 m-fullwidth">
              <GlDatePicker
                v-model="transactionsListDates"
                :disabled-range="disabledRange"
                label="filter by date & time "
                name="Date range"
                @apply="setDate(transactionsListDates)"
                @cancel="handleDateClose"
                @clear="handleDateClose"
              />
            </div>
            <div
              class="flex align-center mt-3 pointer"
              @click="clearFilters"
            >
              <gl-icon
                :height="24"
                name="clear-large-dark"
                :width="24"
              />
              <div class="bold white-space-nowrap">
                Clear all
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="clusters && clusters.length"
        class="mt-3 flex"
      >
        <GlButton
          class="mr-3"
          dark
          :loading="loadingAll"
          title="Keep all"
          @click="handleKeepAll"
        />
        <GlButton
          dark
          :loading="loadingAll"
          title="Apply all"
          @click="handleApplyAll"
        />
      </div>
      <div
        v-if="clusters && clusters.length === 0"
        class="conflicts-empty"
      >
        No unresolved conflicts
      </div>
      <div v-else>
        <div
          v-for="(cluster, index) in clusters"
          :key="index"
          class="mt-3 overflow-auto m-fullwidth"
        >
          <div class="flex space-between">
            <div class="flex-1">
              <div class="flex mb-2 mt-4">
                <div class="mr-2 tagging__key">
                  <div>
                    cluster id
                  </div>
                  <div class="value-label">
                    {{ cluster.oldData.cluster }}
                  </div>
                </div>
              </div>
              <div class="flex align-center">
                <div class="conflicts-sub-block flex-1 mb-2">
                  <div class="flex align-center space-between mb-2">
                    <div class="mr-2 tagging__key">
                      <div>
                        Address
                      </div>
                      <div
                        class="value-label"
                      >
                        {{ cluster.oldData.address || '-' }}
                      </div>
                    </div>
                    <button
                      class="gl-button gl-button--dark gl-button--padder gl-button-bulk conflicts-btn"
                      @click="keepConflicts('cluster', cluster.newData._id)"
                    >
                      Keep
                    </button>
                  </div>
                  <div class="flex align-center mb-2">
                    <div class="mr-2 tagging__key flex-1">
                      <div>
                        Owner
                      </div>
                      <div
                        class="value-label"
                        :class="{'was-changed': wasChanged(cluster.oldData.owner, 'owner', index)}"
                      >
                        {{ capitalizeFirstLetter(cluster.oldData.owner) || '-' }}
                      </div>
                    </div>
                    <div class="mr-2 flex-1">
                      <div class="tagging__key">
                        Type
                      </div>
                      <GlTag
                        v-if="cluster.oldData.type.length > 0"
                        class="mr-1 mb-1"
                        :score="cluster.oldData.type[0].score && cluster.oldData.type[0].score"
                        :tag="cluster.oldData.type[0].name && cluster.oldData.type[0].name"
                      />
                      <GlTag
                        v-else-if="cluster.oldData.type"
                        class="mr-1 mb-1"
                        :score="cluster.oldData.type.score"
                        :tag="cluster.oldData.type.name"
                      />
                      <div v-else>
                        --
                      </div>
                    </div>
                  </div>
                  <div class="mr-2 tagging__key">
                    <div>
                      Description
                    </div>
                    <div
                      class="value-label"
                      :class="{'was-changed': wasChanged(cluster.oldData.description, 'description', index)}"
                    >
                      {{ capitalizeFirstLetter(cluster.oldData.description) || '-' }}
                    </div>
                  </div>
                </div>
                <div class="ml-4 mr-4">
                  <gl-icon
                    :height="24"
                    name="conflicts-arrow"
                    :width="24"
                  />
                </div>
              </div>
            </div>
            <div class="flex column flex-1">
              <div
                v-for="(clusterItem, i) in cluster.newData._data"
                :key="i"
                class="mb-2"
              >
                <div class="flex mb-2 mt-4">
                  <div class="tagging__key flex-1">
                    <div>
                      user id
                    </div>
                    <div class="value-label">
                      {{ clusterItem._editedBy }}
                    </div>
                  </div>
                  <div class="mr-2 tagging__key flex-1">
                    <div>
                      time of change
                    </div>
                    <div class="value-label">
                      {{ clusterItem._editedAt ? formatDate(clusterItem._editedAt, 'dd.MM.yyyy HH:mm') : '-' }}
                    </div>
                  </div>
                </div>
                <div class="conflicts-sub-block">
                  <div class="flex align-center space-between mb-2">
                    <div class="mr-2 tagging__key">
                      <div>
                        Address
                      </div>
                      <div
                        class="value-label"
                      >
                        {{ clusterItem.address || '-' }}
                      </div>
                    </div>
                    <div
                      v-tooltip.top="{
                        content: 'You are not able to apply type without score',
                        visible: clusterItem.type && isNull(clusterItem.type.score)
                      }"
                    >
                      <button
                        class="gl-button gl-button--dark gl-button--padder gl-button-bulk conflicts-btn"
                        :disabled="clusterItem.type && isNull(clusterItem.type.score)"
                        @click="applyConflicts('cluster', cluster.newData._id, i)"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <div class="flex align-center mb-2">
                    <div class="mr-2 tagging__key flex-1">
                      <div>
                        Owner
                      </div>
                      <div
                        class="value-label"
                        :class="{'to-changed': clusters[index].oldData.owner !== clusterItem.owner}"
                      >
                        {{ capitalizeFirstLetter(clusterItem.owner) || '-' }}
                      </div>
                    </div>
                    <div class="mr-2 flex-1">
                      <div class="tagging__key">
                        Type
                      </div>
                      <GlTag
                        v-if="clusterItem.type && clusterItem.type.name"
                        class="mr-1 mb-1"
                        :score="clusterItem.type && clusterItem.type.score"
                        :tag="clusterItem.type && clusterItem.type.name"
                        :value="clusterItem.type && clusterItem.type.name"
                      />
                    </div>
                  </div>
                  <div class="mr-2 tagging__key">
                    <div>
                      Description
                    </div>
                    <div
                      class="value-label"
                      :class="{'to-changed': clusters[index].oldData.description !== clusterItem.description}"
                    >
                      {{ capitalizeFirstLetter(clusterItem.description) || '-' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="clusters && clusters.length > 0"
        class="flex space-between pa-1"
      >
        <div class="flex align-center pa-3 m-column m-pa-0 m-pt-2">
          <div class="mr-2 fs-14 bold  m-fs-12 m-mb-3">
            Rows per page:
          </div>
          <vSelect
            v-model="pagination.perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>

        <o-pagination
          v-if="clusters.length"
          class="stat-pagination"
          :current.sync="pagination.currentPage"
          order="right"
          :per-page="pagination.perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlButton from '@/components/gl-button.vue'
import GlIcon from '@/components/gl-icon'
import GlTag from "@/components/gl-tag";
import GlMenuItem from '@/components/gl-menu-item'
import GlSearchBox from '@/components/gl-search-box'
import vSelect from 'vue-select'
import GlDatePicker from '@/components/gl-date-picker'
// Utils
import { formatDate } from "@/utils/format-date";
import { capitalizeFirstLetter } from "@/utils/text-formatter";
import { toSecondsStart, toSecondsEnd } from '@/utils/format-date'
import moment from "moment";
import { isNull } from "lodash";
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
// Vuex
import { mapActions } from 'vuex'

export default {
  components: {
    GlButton,
    GlIcon,
    GlTag,
    GlMenuItem,
    GlSearchBox,
    vSelect,
    GlDatePicker
  },
  mixins: [deviceWidthMixin],
  props: {
    clusters: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => ({})
    },
    total: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      loading: false,
      loadingAll: false,
      search: '',
      pagesOptions: [5, 10,20, 50, 100],
      transactionsListDates: [],
      startRangeDate: false,
      endRangeDate: false,
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (val.query.tab) {
          if (val.query.tab === 'cluster-conflicts') {
            if (val.query.search) {
              this.search = val.query.search
            }
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.pagination.totalItems = this.total
    this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.perPage)
  },
  methods: {
    ...mapActions('tagging', ['resolveConflict', 'deleteConflict']),
    isNull,
    formatDate,
    toSecondsStart,
    toSecondsEnd,
    capitalizeFirstLetter,
    handleApplyAll() {
      this.loadingAll = true
      this.resolveConflict({ type: 'allClusters', index: 0, id: this.clusters[0].newData._id }).then(() => {
        this.searching()
      }).finally(() => {
        this.loadingAll = false
      })
    },
    handleKeepAll() {
      this.loadingAll = true
      this.deleteConflict({ type: 'allClusters', index: 0, id: this.clusters[0].newData._id }).then(() => {
        this.searching()
      }).finally(() => {
        this.loadingAll = false
      })
    },
    clearFilters() {
      this.search = ''
      this.transactionsListDates = []
      this.$router.replace({ name: 'labeling', query: { tab: `cluster-conflicts` } }).catch(err => err)
      this.$emit('clear-filters')
    },
    setDate(date) {
      const [from, to] = date

      if (from && to) {
        this.startRangeDate = this.toSecondsStart(from) * 1000;
        this.endRangeDate = this.toSecondsEnd(to) * 1000;
        this.currentPage = 1
        this.loadData();
      } else this.clearDate()
    },
    handleDateClose() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.loadData();
    },
    clearDate() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.loadData();
    },
    disabledRange(date) {
      return date > moment().endOf('day')
    },
    loadData() {
      this.$emit('set-date', { from: this.startRangeDate, to: this.endRangeDate })
    },
    searching() {
      this.$emit('searching', this.search)
      this.$router.replace({ name: 'labeling', query: { tab: `cluster-conflicts`, search: this.search } }).catch(err => err)
    },
    clearSearching() {
      this.search = ''
      this.searching()
      this.$router.replace({ name: 'labeling', query: { tab: `cluster-conflicts` } }).catch(err => err)
    },
    applyConflicts(type, id, index) {
      this.$emit('apply', type, id, index)
    },
    keepConflicts(type, id) {
      this.$emit('keep', type, id)
    },
    pageChange(event) {
      this.$emit('change-page', event)
    },
    countChange(event) {
      this.$emit('change-count', event)
    },
    wasChanged(val, key, index) {
      return !this.clusters[index].newData._data.every(item => item[key] === val)
    }
  }
}
</script>

<style>
.title {
  font-size: 18px;
  font-weight: 600;
}
.value-label {
  font-weight: 500;
  color: var(--dark);
  text-transform: none!important;
  margin-top: 4px;
}

.conflicts-sub-block {
  background-color: #fff;
  padding: 18px;
  border-radius: 3px;
  border: 1px solid var(--dark-grey-d-3);
}

.conflicts-btn {
  /*width: 240px;*/
}
</style>
